import { useState } from "react";
import './LazyIframeYT.css';
export default function LazyIframeYT({
  title,
  youtubeID,
  showControls
}) {
  const [showVideo, setShowVideo] = useState(false);

  return (
    <div>
      {showVideo ? (
        <iframe
          title={'Elemental Experts - Get Ahead in CBSE Class 11 & 12 Chemistry'}
          width={"280px"}
          height={"157px"}
          max-width={"100%"}
          src={`https://www.youtube-nocookie.com/embed/${youtubeID}?controls=${showControls}`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      ) : (
        <button
          type="button"
          onClick={() => setShowVideo(true)}
          className="group relative aspect-[16/9] w-full"
          aria-label={`Play video ${title}`}
          style={{border: "none", background: "transparent"}}
        >
            {/* <img
            src={
              `https://img.youtube.com/vi/${youtubeID}/maxresdefault.jpg`
            }
            alt=""
            width={"560px"}
            height={'315px'}
          /> */}

        <div className="thumbnail-container">
            <div className="thumbnail-overlay">
                <div className="youtube-button"></div>
            </div>
            <img className="thumbnail-image" 
            src={
              `https://img.youtube.com/vi/${youtubeID}/maxresdefault.jpg`
            }
            width={"280px"}
            height={"157px"}
            alt="Introduction to Elemental Experts" />
            <i className="play-button fa fa-play"></i>
        </div>
        </button>
      )}
    </div>
  );
}