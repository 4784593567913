import React, { Component } from 'react';
import './Header.css';
import { Link } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getAuth, signInWithPopup, updateProfile, GoogleAuthProvider } from "firebase/auth";

import logoSmall from './assets/logo-small.png';
import logoMedium from './assets/logo-medium.png';
import logoLarge from './assets/logo-large-light.png';

const firebaseConfig = {
  apiKey: "AIzaSyBzUYD-Rj6AwGtyDMHUoZGet3oiUjnS6vU",
  authDomain: "elemental-experts-6a2ff.firebaseapp.com",
  projectId: "elemental-experts-6a2ff",
  storageBucket: "elemental-experts-6a2ff.appspot.com",
  messagingSenderId: "7438449953",
  appId: "1:7438449953:web:90f5c2107fd8dc99c2d260",
  measurementId: "G-B4RZFJY69J"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);



class Header extends Component {
  state = {
    isSignedIn: false, // Local signed-in state.
    logo: logoSmall,
    user: null,
  };
  constructor(props) {
    super(props);
  }
    // Determine which logo to display based on window width
  handleResize = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 768) {
      this.setState({logo:logoSmall});
    } else if (screenWidth < 992) {
      this.setState({logo:logoMedium});
    } else {
      this.setState({logo: logoLarge});
    }
  };

  handleLoginClick = () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    signInWithPopup(auth, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      const user = result.user;
      this.setState({user: user, isSignedIn: true});

    }).catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
      console.log(errorCode);
      console.log(errorMessage);
    });
  };
  componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      (user) => {
        this.setState({isSignedIn: !!user});
      } 
    );
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount() {
    this.unregisterAuthObserver();
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
  if (!this.state.isSignedIn) {
    return (
      
      <header className="header">
        <div className="logo">
        <Link to="/">
          <img src={this.state.logo} alt="Elemental Experts Logo" />
          <span className="logo-text">    </span>                  
        </Link>
        </div>
        <nav className="nav">
          {/* <a href="/" className="nav-link">Home</a>
          <a href="/" className="nav-link">Contact</a> */}
          <div id="button-sign-in">
          <button className='login-btn' onClick={this.handleLoginClick}>
            Login
          </button>
          </div>
        </nav>
      </header>
    );
  }
  else {
    return (
    <header className="header">
      <div className="logo">
        <Link to="/">
          <img src={this.state.logo} alt="Elemental Experts Logo" />
          <span className="logo-text">    </span>                  
        </Link>
      </div>
      <nav className="nav">
        {/* <a href="/" className="nav-link">Home</a>
        <a href="/" className="nav-link">Contact</a> */}

        <div className="signoutBtn">
          <a className="btnClrd orange" onClick={() => firebase.auth().signOut()} >Sign Out</a>
        </div>
        <div className="loggedInUserDetails">
          <div className="displayPhoto">
            <img id="loggedInUserImage" src={firebase.auth().currentUser.photoURL}></img>
          </div>
        </div>
      </nav>
    </header>
    )
  }
};
}
export default Header;
