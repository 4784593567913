import React from 'react';
import './Modal.css';

const Modal = ({ onClose, heading, children }) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-header">
            <h2 style={{color:"black", background:"white"}}>{heading}</h2>
          <a className="modal-close" onClick={onClose}>
          <i class="fa fa-times" aria-hidden="true"></i>
          </a>
        </div>
        <div className="modal-body">{children}</div>
      </div>
      <div className="modal-overlay" onClick={onClose}></div>
    </div>
  );
};

export default Modal;
