import React from 'react';
import { Link } from 'react-router-dom';
import './ChapterList.css';
import SEO from './SEO';

const ChapterList = ({ chapters }) => {
  // Render a list of chapters as links to individual chapter pages
  const renderChapterList = () => {
    return chapters.map((chapter, index) => (
        <Link key={chapter.id} to={`/notes/${chapter.id}`} className="gradient-button">
        <div className='highlightsButton'>    
            <div className="title">
                {chapter.name}
            </div>
        </div>                    
        </Link>
    ));
  };

  return (
    <>
    <SEO title={"Elemental Experts | Chemistry Notes"}
    desc={"Top Quality Chemistry Notes for CBSE class XI, XII, NEET and JEE exams from Elemental Experts"}></SEO>
    
    <div className='notes-list-container'>
      <h1>Chapter Notes</h1>
      <ul>{renderChapterList()}</ul>
    </div>
    </>
  );
};

export default ChapterList;