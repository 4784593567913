import React, { useState } from 'react';
import './Contact.css';
const Contact = () => {
  const [formLoaded, setFormLoaded] = useState(false);

  const loadForm = () => {
    setFormLoaded(true);
  }

  return (
    <div className='contact-g-form'>
      {formLoaded == false && <button className='gradient-button-g-form' onClick={loadForm}>Book Demo Now</button>}
      {formLoaded && (
        <iframe 
          title='Elemental Experts - Enquiry'
          loading='lazy'
          src="https://docs.google.com/forms/d/e/1FAIpQLSe9DYG5TR7-iNIrUz2z47WtCF4yKT0QBzTdHESfqMGidjfURw/viewform?embedded=true" 
          width="100%"
          height="1343" 
          frameborder="0" 
          marginheight="0" 
          marginwidth="0"
        />
      )}
    </div>
  );
};

export default Contact;
