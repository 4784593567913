import React, { Component } from 'react';
import './NotFound.css';
import { Helmet } from 'react-helmet-async';
import logoLarge from './assets/logo-large-light.png';

class NotFound extends Component{
  constructor(props) {
    super(props);
  }
  componentDidMount() {

  }
  render() {
		return (
      <>
      <Helmet>
        <meta 
          property="og:title" 
          content="Elemental Experts | Not Found" 
        />
        <title>Elemental Experts | Not Found</title>
        <meta
          property="og:description"
          content="Best online chemistry tutors. Learn chemistry for Boards, NEET, JEE exams with our online course. Get expert guidance and practice tests. Personalised attention."
        />
        <meta
          name="keywords"
          content="chemistry, NEET, JEE, class XI, class XII, students, online course, expert guidance, practice tests, science, state boards, Elemental Experts"
        />
        <meta property="og:image" content={logoLarge} />
        <meta property="og:url" content="https://elementalexperts.in/chemistry" />
      </Helmet>
      <div id="notfound">
      <div class="notfound">
        <div class="notfound-404">
          <h3>Oops! Page not found</h3>
          <h1><span>4</span><span>0</span><span>4</span></h1>
        </div>
        <h2>we are sorry, but the page you requested was not found</h2>
      </div>
    </div>
    </>
    )
  }
}

export default NotFound;