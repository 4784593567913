import React from 'react';
import './WhatsAppButton.css';

const WhatsappButton = ({ phoneNumber }) => {
  return (
    <div className="whatsapp-button-container">
      <a
        href={`https://api.whatsapp.com/send?phone=${phoneNumber}`}
        target="_blank"
        rel="noreferrer"
        className="whatsapp-button-link"
      >
        <i className="fa fa-whatsapp"></i>
        <span className="whatsapp-button-text">WhatsApp</span>
      </a>
    </div>
  );
};

export default WhatsappButton;
