import React from 'react';
import ReviewCarousel from './ReviewCarousel.js';
import Contact from './Contact';
import './LandingPage.css';
import WhatsAppButton from './WhatsAppButton';
import WelcomeCardNew from './WelcomeCardNew';
import boyA from './assets/boy1.png';
import boyB from './assets/boy2.png';
import girlA from './assets/girl1.png';
import girlB from './assets/girl2.png';
import SEO from './SEO.js';

const reviewsData = [
  {
    id: 1,
    name: "Priyanshu Pal",
    avatar: boyA,
    text: "I had taken chemistry classes from Vaishali Ma'am in 11th and 12th standard and one thing that I have observed in the past two years is that Ma'am can make you fall in love with chemistry.Her way of explaining concepts is tremendously fabulous,one should blindly follow Ma'am's guidance and he/she will surely lead to success.",
  },
  {
    id: 2,
    name: "Sania Balan",
    avatar: girlA,
    text: "I took Vaishali mam's classes for two years during the time when I was in 11th and 12th standard. Her classes were very engaging and she always took up doubts, from the theoretical as well as the practical part. She provided simplified notes that came in handy to me during revisions. Tests were also conducted regularly.",
  },
  {
    id: 3,
    name: "Pragya Smriti",
    avatar: girlB,
    text: "I absolutely loved each and every class of Vaishali ma'am during my academics and would really like every chemistry student to take her classes for that extra shot of knowledge and the way she make us learn every new concept is just very very interesting!!",
  },
  {
    id: 4,
    name: "Soumya Yadav",
    avatar: girlA,
    text: "I have taken vaishali ma'am's classes for 3 months for my neet preparation.she had made all my organic chemistry concepts strong with lots of question practice and revision . Her way of teaching is amazing .she had overcome all my fear related to questions. Best teacher of chemistry.",
  },
  {
    id: 5,
    name: "Sunny Kumar",
    avatar: boyB,
    text: "I had taken chemistry classes from vaishali mam which suddenly gave me interest in chemistry which helps me in scoring high in this interesting subject.",
  },
  {
    id: 6,
    name: "Himanshu Pandey",
    avatar: boyA,
    text: "My son took Vaishali Maam's classes in 11th and 12th classes in last 2 years And I am very happy with my son's performance. I am incredibly grateful for the positive impact Vaishali Maam has had on my son's academic progress. I would highly recommend her to any parent looking for an experienced and passionate chemistry teacher.",
  },
  {
    id: 7,
    name: "Sagar Jha",
    avatar: boyB,
    text: "My nephew took online chemistry classes with Vaishali and had an amazing experience. Her teaching style was engaging, making grasping of  complex concepts with ease. Extremely knowledgeable and passionate about the subject. I would highly recommend Vaishali Sharma's Elemental Experts to anyone looking for quality online chemistry classes.",
  },
  {
    id: 8,
    name: "Nitish Sharma",
    avatar: boyA,
    text: "Result oriented coaching 👍🏻",
  },
  {
    id: 9,
    name: "Suruchi",
    avatar: girlB,
    text: "My younger brother's experience has been quite well, he was able to score 97 marks in chemistry CBSE boards exams. Fortunate to have such a teacher who can make even boring concepts feel like breeze. Highly recommended.",
  },
];

const phoneNumber="+91-9318497397";
function LandingPage() {
  return (
    <>
    <SEO 
      title={"Elemental Experts | Chemistry for Class XI and XII"}
      desc={"Excel in chemistry with Elemental Experts! Get personalized one-on-one online tuitions, video courses, and flashcards for CBSE, state boards, NEET, and JEE. Master chemical bonding and build a strong foundation with engaging MCQs. Join now for top-quality chemistry education from expert tutors"}>
    </SEO>
    <div className="landing-page-container">
      {/* <Header /> */}
      {/* <WelcomeCard/> */}
      <WelcomeCardNew />
      <ReviewCarousel reviews={reviewsData}/>        
      <Contact />
      {/* <section className="landing-page-section" id="videos">
        <div className="landing-page-section-content">
          Upcoming...
        </div>
      </section> */}

      {/* <Footer /> */}
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.573885336738!2d78.38871337600271!3d17.48009758342298!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb917d27c19c21%3A0x41ed46ceb8c23110!2sElemental%20Experts%20-%20Chemistry%20for%20CBSE%2FNEET!5e0!3m2!1sen!2sin!4v1683142369764!5m2!1sen!2sin" width="99%" height="350"   allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
      <WhatsAppButton phoneNumber={phoneNumber}/>
    </div>
    </>
  );
}

export default LandingPage;
