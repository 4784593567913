import React from 'react';
import styles from './ReviewCard.module.css';

const ReviewCard = ({ review, color }) => {
  const { id, name, avatar, text, tags } = review;

  return (
    <div className={styles.card} style={{ backgroundColor: color }}>
      <div className={styles.reviewHeader}>
        <div className={styles.avatar}>
          <img src={avatar} alt="Avatar" />
        </div>
        <div className={styles.name}>{name}</div>
      </div>
      <div className={styles.rating}>5.0 ★★★★★</div>
      <div className={styles.content}>

        <p className={styles.text}>{text}</p>
      </div>
    </div>
  );
};

export default ReviewCard;
