import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import './AddFlashCardDeck.css';

const db = firebase.firestore();

const AddFlashCardDeck = ({topics}) => {
    const [topic, setTopic] = useState('');
    const [cardType, setCardType] = useState('info');
    const [subtitle, setSubtitle] = useState('');
    const [description, setDescription] = useState('');
    const [entity1, setEntity1] = useState('');
    const [entity2, setEntity2] = useState('');
    const [differences, setDifferences] = useState([{ e1: '', e2: '' }]);
    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(false);
    const [jsonObj, setJsonObj] = useState("");

    const handleJsonChange = (event) => {
      setJsonObj(event.target.value);
    }
    const handleTopicChange = (event) => {
      setTopic(event.target.value);
    };
  
    const handleCardTypeChange = (event) => {
      setCardType(event.target.value);
    };
  
    const handleSubtitleChange = (event) => {
      setSubtitle(event.target.value);
    };
  
    const handleDescriptionChange = (event) => {
      setDescription(event.target.value);
    };
  
    const handleEntity1Change = (event) => {
      setEntity1(event.target.value);
    };
  
    const handleEntity2Change = (event) => {
      setEntity2(event.target.value);
    };
  
    const handleEntity1ValueChange = (event, index) => {
      const updatedDifferences = [...differences];
      updatedDifferences[index].e1 = event.target.value;
      setDifferences(updatedDifferences);
    };
  
    const handleEntity2ValueChange = (event, index) => {
      const updatedDifferences = [...differences];
      updatedDifferences[index].e2 = event.target.value;
      setDifferences(updatedDifferences);
    };
  
    const handleAddDifference = () => {
      const updatedDifferences = [...differences, { e1: '', e2: '' }];
      setDifferences(updatedDifferences);
    };
  
    const handleRemoveDifference = (index) => {
      const updatedDifferences = [...differences];
      updatedDifferences.splice(index, 1);
      setDifferences(updatedDifferences);
    };
  
    const handleAddCard = () => {
      if(cardType === 'info')
      {
        const newCard = {
            ty: cardType,
            st: subtitle,
            de: description
          };
          if(cardType == '' || subtitle == "" || description == "")
          {
            console.log("few fields missing");
            return;
          }
          console.log(newCard);
          const updatedCards = [...cards, newCard];
          console.log(updatedCards)
          setCards(updatedCards);
      }
      else {
        const newCard = {
            ty: cardType,
            e1: entity1,
            e2: entity2,
            di: differences
          };
          if(cardType == '' || entity1 == "" || entity2 == '' || differences.length == 0)
          {
            console.log("few fields missing");
            return;
          }
          console.log(newCard);
          const updatedCards = [...cards, newCard];
          console.log(updatedCards);
          console.log(JSON.stringify(updatedCards));
          setCards(updatedCards);
      }

      setCardType('info');
      setSubtitle('');
      setDescription('');
      setEntity1('');
      setEntity2('');
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        console.log(cards);
        try {
          const flashcardDeck = {
            t: topic,
            c: cards,
          };
          await firebase.firestore().collection('flashcards').add(flashcardDeck);
          setTopic('');
          setCards([]);
        } catch (error) {
          console.error(error);
        }
        setLoading(false);
      };
    const handleSubmitJson = async (event) => {
      console.log(jsonObj);
      console.log(JSON.parse(jsonObj));
      setCards(JSON.parse( jsonObj) );
      try {
        const flashcardDeck = {
          t: topic,
          c: JSON.parse(jsonObj),
        };
        await firebase.firestore().collection('flashcards').add(flashcardDeck);
        setJsonObj('');
        setCards([])
      } catch (error) {
        console.error(error);
      }
    }
  return (
    
    <div className='add-flashcard-deck-container'>
      <form onSubmit={handleSubmit}>
        <div>
        <div className='form-group'>
          <label htmlFor='topic'>Topic:</label>
          <select id='topic' value={topic} onChange={handleTopicChange}>
            <option value=''>Select a topic</option>
            {topics.map(t => (
              <option key={t.id} value={t.id}>{t.id}</option>
            ))}
          </select>
        </div>
        <div className='form-group'>
          <label htmlFor='cardType'>Card Type:</label>
          <select id='cardType' value={cardType} onChange={handleCardTypeChange}>
            <option value='info'>Information Card</option>
            <option value='difference'>Difference Card</option>
          </select>
        </div>
        {cardType === 'info' ? (
          <div className='form-group'>
            <label htmlFor='subtitle'>Subtitle:</label>
            <input type='text' id='subtitle' value={subtitle} onChange={handleSubtitleChange} required />
          </div>
        ) : (
          <div className='form-group'>
            <label htmlFor='entity1'>Entity 1:</label>
            <input type='text' id='entity1' value={entity1} onChange={handleEntity1Change} required />
          </div>
        )}
        {cardType === 'info' ? (
          <div className='form-group'>
            <label htmlFor='description'>Description:</label>
            <textarea id='description' value={description} onChange={handleDescriptionChange} required />
          </div>
        ) : (
          <div className='form-group'>
            <label htmlFor='entity2'>Entity 2:</label>
            <input type='text' id='entity2' value={entity2} onChange={handleEntity2Change} required />
          </div>
        )}
        {cardType === 'difference' && (
          <div className='form-group'>
            <label htmlFor='differences'>Differences:</label>
            {differences.map((difference, index) => (
              <div key={index}>
                <input type='text' placeholder='Entity 1 Value' value={difference.e1} onChange={(e) => handleEntity1ValueChange(e, index)} required />
                <input type='text' placeholder='Entity 2 Value' value={difference.e2} onChange={(e) => handleEntity2ValueChange(e, index)} required />
                {index > 0 && (
                  <button type='button' onClick={() => handleRemoveDifference(index)}>Remove</button>
                )}
              </div>
            ))}
            <button type='button' onClick={handleAddDifference}>Add Difference</button>
          </div>

        )}
        <button type='button' onClick={handleAddCard}>Add Card</button>
        </div>
        <button onClick={handleSubmit}>Submit</button>
      </form>
      <form>
        <div className='form-group'>
          <label htmlFor='topic'>Topic:</label>
          <select id='topic' value={topic} onChange={handleTopicChange}>
            <option value=''>Select a topic</option>
            {topics.map(t => (
              <option key={t.id} value={t.id}>{t.id}</option>
            ))}
          </select>
        </div>
        <input type="text" placeholder='Add Json here' onChange={(e) => handleJsonChange(e)}>
        </input>
        <button type='button' onClick={() => handleSubmitJson()}>Submit</button>
      </form>
    </div>
  );
}

export default AddFlashCardDeck;