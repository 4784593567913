import React from 'react';
import { useParams } from 'react-router-dom';
import './ChapterPage.css';
import NotFound from './NotFound';
import SEO from './SEO';

const ChapterPage = ({ chapters }) => {
  const { id } = useParams('id');

  // Find the chapter object based on the id parameter from the URL
  const chapter = chapters.find(chapter => chapter.id === id);

  // Render the notes for the chapter in a Google Docs iframe
  const renderNotes = () => {
    // Assuming you have the Google Docs URL for the notes in each chapter object
    return (
    <div>
      <h1>Notes for {chapter.name}</h1>
      <iframe
        src={chapter.notesUrl}
        width="100%"
        height="700px"
        title={`Notes for ${chapter.name}`}
      ></iframe>
    </div>
    );
  };

  return (
    <>
    <SEO title={chapter ? "Elemental Experts | " + chapter.name + " Notes": "Chemistry Notes"} 
    desc={(chapter ? chapter.name + " Notes": "Chemistry Notes") + " for CBSE class XI, XII, NEET and JEE exams from Elemental Experts"}></SEO>
    <div className='notes-container'>
    {chapter &&
      renderNotes()
    }
    {chapter == null && <NotFound ></NotFound>}
    </div>
    </>
  );
};

export default ChapterPage;
