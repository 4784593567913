import React from 'react';
import './Modal.css';
import { Helmet } from 'react-helmet-async';
import logoLarge from './assets/logo-large-light.png';

const SEO = ({ title, desc, image }) => {
  return (
    <Helmet>
    <meta 
      property="og:title" 
      content={title}
    />
    <title>{title}</title>
    <meta 
      property="title" 
      content={title}
    />
    <meta
      name="description"
      content={desc}
    />
    <meta
      property="description"
      content={desc}
    />
    <meta
      property="og:description"
      content={desc}
    />
    <meta name="twitter:title" 
        content={title}
    />
    <meta name="twitter:description" 
      content={desc}
    />
    <meta
      name="keywords"
      content="flashcards, NEET, JEE, MCQs, chemistry syllabus, Chemistry videos, Class XI, Class XII, CBSE, NEET, JEE, state boards, one-on-one, group, online tuition, video lectures, lessons, tutorials, exam preparation, topics, concepts, education, study material, online learning, curriculum, private chemistry tuition, personalized chemistry coaching, chemistry tutoring, virtual chemistry classes, online chemistry classes, interactive chemistry lessons, tailored chemistry education, chemistry coaching for Class XI and XII, chemistry coaching for NEET and JEE, online chemistry tutors, virtual chemistry lessons, live chemistry classes, interactive chemistry tutorials, on-demand chemistry coaching, comprehensive chemistry study material, Elemental Experts"
    />
    <meta property="og:image" content={logoLarge} />
    <meta property="og:url" content="https://elementalexperts.in/flashcards" />
    <meta property="og:type" content="website"/>
    {/* <meta name="twitter:card" content="summary_large_image"/> */}

    <meta name="twitter:image" content={logoLarge}/>
  </Helmet>
  );
};

export default SEO;
