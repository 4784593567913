import React from 'react';
import { Link } from 'react-router-dom';
import './FlashCards.css';
import SEO from './SEO';

const flashcardTopics = [
  {
    id: "basic-concepts",
    title: 'Basic Concepts of Chemistry',
    description: 'Learn about basic concepts of Chemistry. Learn how measurements are made, mole concept, concentration of solution and different units',
    link: '/flashcards/basic-concepts'
  },
  {
    id: "chemical-bonding",
    title: 'Chemical Bonding',
    description: 'Learn about different types of chemical bonds like Ionic Bonds, Covalent Bonds and how these bonds affect properties of the material',
    link: '/flashcards/chemical-bonding'
  },
  {
    id: "elements-and-properties",
    title: 'Elements and their important properties',
    description: 'Learn about the elements in the universe. Learn their important properties, atomic number and atomic mass.',
    link: '/flashcards/elements-and-properties'
  },
  {
    id: "chemistry-formulae",
    title: 'Most Important Chemistry Formulae',
    description: 'Learn about the various Physical Chemistry Formulae. Learn the equations, parameters and theie importance.',
    link: '/flashcards/chemistry-formulae'
  },
  {
    id: "organic-reaction-mechanisms",
    title: 'Important Organic Chemical Reaction Mechanisms',
    description: 'Learn about the various organic named reactions, their mechanisms and tricks to remember. All at one place.',
    link: '/flashcards/organic-reaction-mechanism'
  }
];
const colors = ['#F1F333', '#E2442F', '#FFC900', '#90A8ED', "#23a094"];
const FlashCards = () => {
  return (
    <>
    <SEO title={"Elemental Experts | Chemistry Flashcards for Class XI and XII"}
    desc={"Elemental Experts' Chemistry flashcards to Master Class 11 and 12 chemistry! Perfect for NEET and JEE prep. Ideal for self-study or revision, our interactive flashcards cover all syllabus topics, and practice problems."}></SEO>
    
    <div className='flashcards'>
      <h1>Chemistry Flashcards</h1>
      <div className='fc-carousel'>
        {flashcardTopics.map((topic, index) => (
            <Link to={topic.link} key={topic.id} className="fc-topic-container" style={{ backgroundColor: colors[index % flashcardTopics.length]}}>
              <div className='fc-card'>    
                  <div className="title">
                    {topic.title}
                  </div>
                  <div className='description'>
                  <p>{topic.description}</p>
                  </div>
              </div>                    
            </Link>
        ))}
      </div>
    </div>
    </>
  );
};

export default FlashCards;
