import LazyIframeYT from './LazyIframeYT';
import { Link } from 'react-router-dom';
import './WelcomeCardNew.css';
import Typewriter from "typewriter-effect";

const WelcomeCardNew = () => {

  // const [width, setWidth] = useState(window.innerWidth);

  // useEffect(() => {
  //   const handleResize = () => setWidth(window.innerWidth);
  //   window.addEventListener('resize', handleResize);
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);

  // const isDesktop = width >= 768;

  // const particlesInit = useCallback(async (engine) => {
  //   //console.log(engine);
  //   // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
  //   // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
  //   // starting from v2 you can add only the features you need reducing the bundle size
  //       await loadFull(engine);
  //   }, []);

  //   const particlesLoaded = useCallback(async (container) => {
  //       //console.log(container);
  //   }, []);

  return (
    <div className="welcome-card">
      {/* {isDesktop && (<Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          fpsLimit: 60,
          interactivity: {
            events: {
              onClick: {
                enable: true,
                mode: "repulse"
              },
              onHover: {
                enable: false,
                mode: "repulse"
              },
              resize: true
            },
            modes: {
              push: {
                quantity: 4
              },
              repulse: {
                distance: 200,
                duration: 0.4
              }
            }
          },
          particles: {
            color: {
              value: ["#ff90e8", '#f1f333'],
            },
            links: {
              color: ["#ff90e8", '#f1f333'],
              distance: 150,
              enable: true,
              opacity: 0.7,
              width: 1
            },
            collisions: {
              enable: true
            },
            move: {
              direction: "none",
              enable: true,
              outModes: {
                default: "bounce"
              },
              random: false,
              speed: 1,
              straight: false
            },
            number: {
              density: {
                enable: true,
                area: 800
              },
              value: 80
            },
            opacity: {
              value: 0.7
            },
            shape: {
              type: "circle"
            },
            size: {
              value: { min: 1, max: 5 }
            }
          },
          detectRetina: true
        }}
        />)} */}
        <div className="welcome-card-content">
            <div className='left-content'>
            <span className="highlight-contain"><span className="highlight"><h1>Experience Difference</h1> </span></span>
                <div className="highlight-container">
                    {/* <span>{highlightText}</span> */}
                    <Typewriter
                        onInit={(typewriter)=> {
                            typewriter
                            .typeString('Expert Teachers')
                            .pauseFor(1000)
                            .deleteAll()
                            .typeString('Personalized Learning')
                            .pauseFor(1000)
                            .deleteAll()
                            .typeString('Join Online Classes Now.')
                            .start();
                        }}
                    />
                </div>
                <div className='important-links'>
                <Link to="/chemistry" className="gradient-button">
                    <div className='highlightsButton'>    
                        <div className="title">
                            Chemistry Videos
                        </div>
                        <div className="cont-path">
                            Start Learning Now →
                        </div>
                    </div>                    
                </Link>
                <Link to="/flashcards" className="gradient-button">
                    <div className='highlightsButton'>    
                        <div className="title">
                            Chemistry Flashcards
                        </div>
                        <div className="cont-path">
                            Super Fast Revision →
                        </div>
                    </div>                    
                </Link>
                <Link to="/notes" className="gradient-button">
                    <div className='highlightsButton'>    
                        <div className="title">
                            Chemistry Notes
                        </div>
                        <div className="cont-path">
                            Expert Notes →
                        </div>
                    </div>                    
                </Link>
                </div>
            </div>
            <div className='right-content'>
                <LazyIframeYT title={"Introduction"} youtubeID={"cFLmrHfIvG0"} showControls={0}/>
            </div>
        </div>
     </div>
    );
  }

export default WelcomeCardNew;
