import React, { useState } from "react";
import LazyIframeYT from "./LazyIframeYT";
import "./VideoList.css";
import { Helmet } from 'react-helmet-async';
import logoLarge from './assets/logo-large-light.png';
import SEO from "./SEO";
const VideoGroup = ({
  chapter,
  chapterTitle,
  initialVideos,
  isOpen,
  setOpenGroup,
  setIsInit
}) => {
  const [selVid, setSelVid] = useState(initialVideos.length > 0 ? initialVideos[0].id : null);
  const [groupVideos, setGroupVideos] = 
                useState(initialVideos);

  const handleClick = () => {
    setOpenGroup(isOpen ? null : chapter);
    setIsInit(false);
  };

  const handleVideoClick = (video) => {
    if (video.id !== selVid) {
      setSelVid(video.id);
      setOpenGroup(chapter);
      const filteredVideos = groupVideos.filter((v) => v.id !== video.id);
      const reorderedVideos = [video, ...filteredVideos];
      setGroupVideos(reorderedVideos);
    }
  };  

  return (
    <>
    <SEO title={"Elemental Experts | Chemistry Videos for Class XI and XII"}
      desc={"Explore our comprehensive video course to study chemistry for CBSE class XI, XII, NEET and JEE exams. Our Expert Teachers offer high-quality video courses for deep and easy to understand experience."}>
    </SEO>
    <div className="video-group">
      <div className="video-group-header" onClick={handleClick}>
        <h3>{chapterTitle}</h3>
        <button className={`collapsible-button ${isOpen ? "open" : "closed"}`}>
          <span></span>
          <span></span>
        </button>
      </div>
      {isOpen &&
        groupVideos.length > 0 &&
        groupVideos.map((video) => (
        <div>
          <div
            className={`video-container ${
              video.id === selVid ? "selected" : ""
            }`}
            key={video.id}
            onClick={() => {
                if (video.id !== selVid) {
                    setSelVid(video.id);
                    setOpenGroup(chapter);
                    const filteredVideos = groupVideos.filter((v) => v.id !== video.id);
                    const reorderedVideos = [video, ...filteredVideos];
                    setGroupVideos(reorderedVideos);
                  }
                }
            }
          >
            
            <LazyIframeYT title={video.title} youtubeID={video.id} />
            <h4>{video.title}</h4>
          </div>
          {video.id === selVid ? (
            <div className="video-description">
              {video.description}
            </div>
          ) : null}
        </div>
        ))}
    </div>
    </>
  );
};

const VideoList = ({ videos }) => {
    const [openGroup, setOpenGroup] = useState(null);
    const [isInit, setIsInit] = useState(true);

    // Group the videos by chapter
    const videoGroups = videos.reduce((groups, video) => {
      const chapter = video.ch;
      const chapterTitle = video.chTitle;
      if (!groups[chapter]) {
        groups[chapter] = { chapterTitle, videos: [] };
      }
      groups[chapter].videos.push(video);
      return groups;
    }, {});
  
    const handleGroupClick = () => {
      setOpenGroup(null);
    };
  

    return (
      <>
      <Helmet>
        <meta 
          property="og:title" 
          content="Elemental Experts | Chemistry Videos for Class XI and XII" 
        />
        <meta 
          property="title" 
          content="Elemental Experts | Chemistry Videos for Class XI and XII" 
        />
        <title>Elemental Experts | Chemistry Videos for Class XI and XII</title>
        <meta
          property="description"
          content="Elemental Experts is the best platform to study chemistry for CBSE class XI, XII, NEET and JEE exams. Our Expert Teachers offer high-quality video courses, chemistry flashcards for quick revision. We offer best chemistry tuition services are designed to help students excel."
        />
        <meta
          property="og:description"
          content="Elemental Experts is the best platform to study chemistry for CBSE class XI, XII, NEET and JEE exams. Our Expert Teachers offer high-quality video courses, chemistry flashcards for quick revision. We offer best chemistry tuition services are designed to help students excel."
        />
        <meta
          name="keywords"
          content="Chemistry videos, Class XI, Class XII, CBSE, NEET, JEE, state boards, one-on-one, group, online tuition, video lectures, lessons, tutorials, exam preparation, topics, concepts, education, study material, online learning, curriculum, private chemistry tuition, personalized chemistry coaching, chemistry tutoring, virtual chemistry classes, online chemistry classes, interactive chemistry lessons, tailored chemistry education, chemistry coaching for Class XI and XII, chemistry coaching for NEET and JEE, online chemistry tutors, virtual chemistry lessons, live chemistry classes, interactive chemistry tutorials, on-demand chemistry coaching, comprehensive chemistry study material, Elemental Experts"
        />
        <meta property="og:image" content={logoLarge} />
        <meta property="og:url" content="https://elementalexperts.in/chemistry" />
        <meta property="og:type" content="website"/>
        {/* <meta name="twitter:card" content="summary_large_image"/> */}
        <meta name="twitter:title" content="Elemental Experts | Chemistry for Class XI and XII"/>
        <meta name="twitter:description" 
              content="Elemental Experts is the best platform to study chemistry for CBSE class XI, XII, NEET and JEE exams. Our Expert Teachers offer high-quality video courses, chemistry flashcards for quick revision. We offer best chemistry tuition services are designed to help students excel."
        />
        <meta name="twitter:image" content={logoLarge}/>
      </Helmet>
      <div className="video-list">
        {Object.entries(videoGroups).map(([chapter, { chapterTitle, videos }]) => (
        <div className="video-group-container" key={chapter}>
            <VideoGroup
            key={chapter}
            chapter={chapter}
            chapterTitle={chapterTitle}
            initialVideos={videos}
            isOpen={isInit == true && chapter == 4 ? true: openGroup === chapter}
            setOpenGroup={setOpenGroup}
            setIsInit={setIsInit}
            />
        </div>
        ))}
      </div>
      </>
    );
  };
  

  export default VideoList;