import React, { Component } from 'react';
import './Footer.css';
import logo from './assets/logo-large-light.png';

class Footer extends Component{

  state = {
  };
  constructor(props) {
    super(props);
  }
  componentDidMount() {
  }
  render() {
    return (
      <div className='footer-container'>
        <div className = "footer">

        <div className="logo">
            <img src={logo} alt="Elemental Experts Logo" />
        </div>

        <div className = "footer-ref">
            <div className="f-ref-r">
                <div className="f-ref-i">
                    Jobs
                </div>
                <div className="f-ref-i">
                    Privacy Policy
                </div>
                <div className="f-ref-i">
                    Terms of Use
                </div>
                <div className="f-ref-i">
                © Elemental Experts 2023
                </div>
            </div>
        </div>

        <div className="footer-social-accounts">
                <a target="_blank" aria-label='YouTube' href="https://www.youtube.com/@ElementalExperts">
                    <i className="fa fa-youtube"></i>
                </a>
                <a target="_blank" aria-label='Instagram' href="https://www.instagram.com/elemental.experts">
                    <i className="fa fa-instagram"></i>
                </a>
                <a target="_blank" aria-label='Facebook' href="https://www.facebook.com/ElementalExperts">
                    <i className="fa fa-facebook"></i>
                </a>
                <a target="_blank" aria-label='LinkedIn' href="https://www.linkedin.com/company/elemental-experts">
                    <i className="fa fa-linkedin"></i>
                </a>
                <a className="mail-text" aria-label='Email' href="mailto:hello@elementalexperts.in"> 
                    <i className="fa fa-envelope"></i>  
                </a>
            </div>
        </div>
        <div className = "footer-ref">
            <div className="f-ref-r">
            <div className="footer-contact-us">
                <div className="f-ref-i">
                    Contact Us
                </div>
                <div className="f-ref-i">
                    +91-9318497397
                </div>
                <div className="f-ref-i">
                <a aria-label='Mail us' href="mailto:hello@elementalexperts.in">
                    hello@elementalexperts.in
                </a>
                </div>
            </div>
            </div>
        </div>
      </div>
    );
	}
}

export default Footer;