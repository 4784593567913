import React from "react";
import ReviewCard from "./ReviewCard";
import styles from "./ReviewCarousel.module.css";

const ReviewCarousel = ({ reviews }) => {
  const colors = ['#F1F333', '#E2442F', '#FFC900', '#90A8ED', "#23a094"];
  return (
    <div className={styles.carousel}>
      {reviews.map((review, index) => (
        <div
          key={index}
          className={styles.cardWrapper}>
          <ReviewCard review={review} color={colors[index % colors.length]}/>
        </div>
      ))}
    </div>
  );
};

export default ReviewCarousel;
