import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { doc, getDoc } from "firebase/firestore";
import FlashCardDeck from './Deck';
import NotFound from './NotFound';
import SEO from './SEO';

const firebaseConfig = {
    apiKey: "AIzaSyBzUYD-Rj6AwGtyDMHUoZGet3oiUjnS6vU",
    authDomain: "elemental-experts-6a2ff.firebaseapp.com",
    projectId: "elemental-experts-6a2ff",
    storageBucket: "elemental-experts-6a2ff.appspot.com",
    messagingSenderId: "7438449953",
    appId: "1:7438449953:web:90f5c2107fd8dc99c2d260",
    measurementId: "G-B4RZFJY69J"
};
// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);

// Use these for db & auth
const db = firebaseApp.firestore();
// const auth = firebase.auth();

const topicTitle = {
  "chemical-bonding": "Chemical Bonding | Theories, Parameters and Properties",
  "basic-concepts": "Some Basic Concepts of Chemistry | Mole Concept",
  "elements-and-properties": "Elements And Their Important Properties",
  "chemistry-formulae": "All inorganic chemistry formulae at one place",
  "organic-reaction-mechanism": "All Named Reactions of Organic Chemistry"
}

const FlashCardTopicPage = () => {
    const { topic } = useParams('topic');
    const {id} = useParams('id');
    const {page} = useParams('page');

    const [flashcardDecks, setFlashcardDecks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [lastDoc, setLastDoc] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [defaultPage, setDefaultPage] = useState(0);

    localStorage.clear();
    useEffect(() => {
      setLoading(true);
      setFlashcardDecks([]);
      setLastDoc(null);
      setHasMore(true);
  
      const fetchFlashcardDecks = async () => {
        try {
          if(id) {
            const docRef = doc(db, "flashcards", id);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
              // console.log("Document data:", docSnap.data());
              const docs = ([{ id: docSnap.id, cards: docSnap.data().c }]);
              setFlashcardDecks(prevState => [...prevState, ...docs]);
              setLoading(false);
              setHasMore(false);
              if(page && page < docs[0].cards.length) {
                setDefaultPage(page);
              }
            } else {
              // docSnap.data() will be undefined in this case
              setFlashcardDecks([]);
              setLoading(false);
              setHasMore(false);
              console.log("No such document!");
            }
          }
          else {
            let ob = 'c';
            if(topic == "elements-and-properties") {
              ob = 'o';
            }
            const querySnapshot = await db.collection('flashcards')
              .where('t', '==', topic)
              .orderBy(ob)
              .limit(10)
              .get();
  
            const docs = querySnapshot.docs.map(doc => ({ id: doc.id, cards: doc.data().c }));
            // docs.map(deck => {console.log(deck.id + " " + deck.cards.length)})
            setFlashcardDecks(docs);
            setLoading(false);
            if (docs.length < 10) setHasMore(false);
            const lastVisible = querySnapshot.docs[querySnapshot.docs.length-1] || null;
            setLastDoc(lastVisible);  
          }        
        } catch (error) {
          console.error(error);
        }
      };
      fetchFlashcardDecks();
    }, [topic, id, page]);
  
    const handleLoadMore = async () => {
      if (!lastDoc || !hasMore) return;
  
      try {
        let ob = 'c';
        if(topic == "elements-and-properties") {
          ob = 'o';
        }
        const querySnapshot = await db.collection('flashcards')
          .where('t', '==', topic)
          .orderBy(ob)
          .limit(10)
          .startAfter(lastDoc)
          .get();
  
        const docs = querySnapshot.docs.map(doc => ({ id: doc.id, cards: doc.data().c }));
        setFlashcardDecks(prevState => [...prevState, ...docs]);
        if (docs.length < 10) setHasMore(false);
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length-1] || null;
        // docs.map(deck => {console.log(deck.id + " " + deck.cards.length)})
        setLastDoc(lastVisible);
      } catch (error) {
        console.error(error);
      }
    };
    const colors = ['#F1F333', '#E2442F', '#FFC900', '#90A8ED', "#23a094"];
    return (
      <>
      <SEO title={(topicTitle[topic] ? topicTitle[topic] : "Chemistry") + " Flashcards for class XI and XII"}
      desc={"Elemental Experts' " + (topicTitle[topic] ? topicTitle[topic] : "Chemistry") + " flashcards to Master Class 11 and 12 chemistry! Perfect for NEET and JEE prep. Ideal for self-study or revision, our interactive flashcards cover all syllabus topics, and practice problems."}></SEO>
      
      <div className={flashcardDecks.length == 0 ? "" : 'flash-card-page-container'}>
        <h2>{topicTitle[topic] ? topicTitle[topic] : topic}</h2>
        <div className={flashcardDecks.length == 0 ? "" : "decks-container"}>
        
        {loading ? (
          <p>Loading...</p>
        ) : flashcardDecks.length > 0 ? (
          <>
            {flashcardDecks.map((flashcardDeck, index) => (
              <FlashCardDeck key={flashcardDeck.id} doc={flashcardDeck.id} topic={topic} cards={flashcardDeck.cards} page={defaultPage} />
            ))}
            
          </>
        ) : <NotFound/> }
      </div>
      {hasMore && (
        <button onClick={handleLoadMore}>Load more</button>
      )}
      </div>
      </>
    );
  };
  

  export default FlashCardTopicPage;