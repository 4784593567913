import React, { useState } from 'react';
import Modal from './Modal';
import "./Modal.css";

import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
 } from 'react-share';

const ShareModal = ({ url, quote, title, summary}) => {
  const [showModal, setShowModal] = useState(false);

  const handleShareClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCopyLink = () => {
    // Logic to copy the link to clipboard
    // You can use a library like clipboard.js to implement this
  };

  return (
    <>
      <a onClick={handleShareClick}><i className="fa fa-share-alt" aria-hidden="true"></i></a>
      {showModal && (
        <Modal onClose={handleCloseModal} heading={"Share Options"}>
          <input className='modal-url-box' type='textbox' value={url}></input>
          <div className='share-modal-btns'>
            <FacebookShareButton quote={quote} url={url}>
                <i className="fa fa-facebook" style={{color:"black"}}></i>
            </FacebookShareButton>
            <TwitterShareButton title={title} url={url}>
                <i className="fa fa-twitter" style={{color:"black"}}></i>
            </TwitterShareButton>
            <LinkedinShareButton title={title} summary={summary} source={"https://elementalexperts.in"} url={url}>
                <i className="fa fa-linkedin" style={{color:"black"}}></i>
            </LinkedinShareButton>
            <WhatsappShareButton title={title} url={url} separator=': '>
                <i className="fa fa-whatsapp" style={{color:"black"}}></i>
            </WhatsappShareButton>
            {/* <button onClick={handleCopyLink}>Copy Link</button>
            Add more share buttons for other social media platforms as needed */}
          </div>
        </Modal>
      )}
    </>
  );
};

export default ShareModal;
