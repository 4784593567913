import React, { useState } from 'react';
import './Deck.css';
import NotFound from './NotFound';
import Latex from 'react-latex';
import ShareModal from './ShareModal';

const FlashCardDeck = ({ doc, topic, cards, page }) => {
  const [cardIndex, setCardIndex] = useState(page);
  const [currentCard, setCurrentCard] = useState(cards ? cards[page] : {});
  const [currentHint, setCurrentHint] = useState(0);
  const [currentSel, setCurrentSel] = useState("");
  // console.log(topic)
  // console.log(doc)
  
  const handleNextCard = () => {
    const nextIndex = (cardIndex + 1) % cards.length;
    const nextCard = cards[nextIndex];
    setCurrentCard(nextCard);
    setCardIndex(nextIndex);
    setCurrentHint(0);
    setCurrentSel("");
  };
  
  const handlePrevCard = () => {
    const prevIndex = (cardIndex - 1 + cards.length) % cards.length;
    const prevCard = cards[prevIndex];
    setCurrentCard(prevCard);
    setCardIndex(prevIndex);
    setCurrentHint(0);
    setCurrentSel("");
  };

  const handleSelectOption = (event) => {
    // console.log(event.currentTarget.id)
    if(currentCard.sel){
      return;
    }
    if(currentCard.ans == 'A' || currentCard.ans == 'a' || currentCard.ans == '1')
      currentCard.ans = 1;
    else if(currentCard.ans == 'B' || currentCard.ans == 'b' || currentCard.ans == '2')
      currentCard.ans = 2;
    else if(currentCard.ans == 'C' || currentCard.ans == 'c'  || currentCard.ans == '3')
      currentCard.ans = 3;
    else if(currentCard.ans == 'D' || currentCard.ans == 'd' || currentCard.ans == '4')
      currentCard.ans = 4;
    currentCard.sel = parseInt(event.currentTarget.id[6]);
    setCurrentSel(currentCard.sel);
  }

  const isTitleCard = currentCard.ty === 't';
  const isDifferenceCard = currentCard.ty === 'd';
  const isInfoCard = currentCard.ty === 'i';
  const isFactorCard = currentCard.ty === 'f';
  const isMCQCard = currentCard.ty === 'q';
  const isMECard = currentCard.ty  === 'm';
  const isRMCard = currentCard.ty === 'rm';

  return (cards && cards.length > 0 ? (
    <div className="flashcard-deck" >
      <div className="flashcard">
        {isTitleCard && (
          <div className="title-card">
            <h1>{currentCard.st}</h1>
            <div className="info-content">
              <p>{currentCard.de}</p>
            </div>
            {currentCard.me ? (<div className="math-eq">
              <Latex>{"$" + currentCard.me + "$"}</Latex>
            </div>) : ""}
          </div>
          
        )}
        {isInfoCard && (
          <div className="info-card">
            <div className="flashcard-title">
              <h2>{currentCard.st}</h2>
            </div>
            <div className="info-content">
              <p>{currentCard.de}</p>
            </div>
          </div>
        )}
        {isDifferenceCard && (
          <div className="difference-card">
            <div className="flashcard-title">
              <h2>{currentCard.e1} vs {currentCard.e2}</h2>
            </div>
            <div className="difference-content">
              <table>
                <tbody>
                  <th>{currentCard.e1}</th> <th>{currentCard.e2}</th>
                  {currentCard.di.map((difference, index) => (
                    <tr key={index}>
                      <td>{difference.e1}</td>
                      <td>{difference.e2}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {isFactorCard && (
          <div className="difference-card">
            <div className="flashcard-title">
              <h2>{currentCard.st}</h2>
            </div>
            <div className="info-content">
              <p>{currentCard.de}</p>
            </div>
            <div className="difference-content">
              <table>
                <tbody>
                  {currentCard.fa.map((factor, index) => (
                    <tr key={index}>
                      <td>{factor.fa1}</td>
                      <td>{factor.de}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {isMECard && (
          <div className="info-card">
            <div className="flashcard-title">
              <h2>{currentCard.st}</h2>
            </div>
            <div className="info-content">
              <p>{currentCard.de}</p>
            </div>
            <div className="math-eq">
              <Latex>{"$" + currentCard.me + "$"}</Latex>
            </div>
          </div>
        )}
        {isRMCard && (
          <div className="info-card">
            <div className="flashcard-title">
              <h2>{currentCard.st}</h2>
            </div>
            <div className="info-content">
              <p>{currentCard.de}</p>
            </div>
            <div className="math-eq">
              <Latex>{"$" + currentCard.me + "$"}</Latex>
            </div>
          </div>
        )}
        {isMCQCard && (
          <div className="mcq-card">
            <div className="flashcard-title">
              <h2>{currentCard.q}</h2>
            </div>
            <div className="info-content">
              <p>{currentCard.de}</p>
            </div>
            <div className="options-content">
            <div className='currently-fc-sel'>{currentSel}</div>
              <table className='fc-mcq-table'>
                <tbody>
                    <tr>
                      <td className={ (currentCard.sel == currentCard.ans && currentCard.sel == 1) ? 
                        "fc-options fc-right-ans" : 
                        currentCard.sel != currentCard.ans && currentCard.sel == 1 ? "fc-options fc-wrong-ans" : 
                        "fc-options"
                        } id={"fc-op-1"} onClick={handleSelectOption}>{currentCard.op[0]}</td>
                      <td className={ (currentCard.sel == currentCard.ans && currentCard.sel == 2) ? 
                        "fc-options fc-right-ans" : 
                        currentCard.sel != currentCard.ans && currentCard.sel == 2 ? "fc-options fc-wrong-ans" : 
                        "fc-options"
                        }  id={"fc-op-2"} onClick={handleSelectOption}>{currentCard.op[1]}</td>
                    </tr>
                    <tr>
                      <td className={ (currentCard.sel == currentCard.ans && currentCard.sel == 3) ? 
                        "fc-options fc-right-ans" : 
                        currentCard.sel != currentCard.ans && currentCard.sel == 3 ? "fc-options fc-wrong-ans" : 
                        "fc-options"
                        }  id={"fc-op-3"} onClick={handleSelectOption}>{currentCard.op[2]}</td>
                      <td className={ (currentCard.sel == currentCard.ans && currentCard.sel == 4) ? 
                        "fc-options fc-right-ans" : 
                        currentCard.sel != currentCard.ans && currentCard.sel == 4 ? "fc-options fc-wrong-ans" : 
                        "fc-options"
                        }  id={"fc-op-4"} onClick={handleSelectOption}>{currentCard.op[3]}</td>
                    </tr>
                </tbody>
              </table>
              
              <div className={currentHint ? 'fc-hint-box' : 'fc-hint-box-hidden'}>{currentCard.hint}</div>
              
            </div>
          </div>
        )}
      </div>
      <div className="buttons button-row">
        <button disabled={cardIndex == 0 ? "disabled" : ""} className={cardIndex == 0 ? "fc-btn-disabled" : ""} onClick={handlePrevCard}>Prev</button>
        {isMCQCard && (<a onClick={() => {setCurrentHint((currentHint + 1) % 2)}}><i className="fa fa-question-circle" aria-hidden="true"></i></a>)}
        <ShareModal quote={currentCard.st} title={currentCard.st} summary={currentCard.st} url={"https://elementalexperts.in/flashcards/"+topic+"/"+doc+"/"+cardIndex}></ShareModal>
        <a target='_blank' href={'/flashcards/'+topic+"/"+doc+"/"+cardIndex}><i className="fa fa-external-link"></i></a>
        <button disabled={cardIndex == cards.length - 1 ? "disabled" : ""} className={cardIndex == cards.length - 1 ? "fc-btn-disabled" : ""} onClick={handleNextCard}>Next</button>
      </div>
    </div>
  ) : <NotFound/>);
};

export default FlashCardDeck;
