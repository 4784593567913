import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import LandingPage from './LandingPage';
import NotFound from './NotFound'; 
import { BrowserRouter, Routes, Route } from "react-router-dom";
import VideoList from './VideoList';
import Header from './Header';
import Footer from './Footer';
import FlashCards from './FlashCards';
import FlashCardTopicPage from './FlashCardTopicPage';
import AddFlashCardDeck from './AddFlashCardDeck';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import ChapterList from './ChapterList';
import ChapterPage from './ChapterPage';
import FlashCardDeck from './Deck';

const root = ReactDOM.createRoot(document.getElementById('root'));

const flashcardTopics = [
  {
    id: "basic-concepts",
    title: 'Basic Concepts of Chemistry',
    description: 'Learn about basic concepts of Chemistry. Learn how measurements are made, mole concept, concentration of solution and different units',
    link: '/flashcards/basic-concepts'
  },
  {
    id: "chemical-bonding",
    title: 'Chemical Bonding',
    description: 'Learn about different types of chemical bonds and how these bonds affect properties of the material',
    link: '/flashcards/chemical-bonding'
  },
  {
    id: "elements-and-properties",
    title: 'Elements and their important properties',
    description: 'Learn about the elements in the universe. Learn their important properties, atomic number and atomic mass.',
    link: '/flashcards/elements-and-properties'
  },
  {
    id: "chemistry-formulae",
    title: 'Most Important Chemistry Formulae',
    description: 'Learn about the various Physical Chemistry Formulae. Learn the equations, parameters and theie importance.',
    link: '/flashcards/chemistry-formulae'
  },
  {
    id: "organic-reaction-mechanism",
    title: 'Important Organic Chemical Reaction Mechanisms',
    description: 'Learn about the various organic named reactions, their mechanisms and tricks to remember. All at one place.',
    link: '/flashcards/organic-reaction-mechanism'
  }

];

const videos = [
  {
    ch : 4,
    chTitle: "Chemical Bonding", 
    id : "LriRSgSzEbw",
    title: "Chemical Bonding | Class 11 Chapter 4 | Introduction | Types of Bonding",
    description: "Welcome to introductory video on chemical bonding, where we'll take you on a fascinating journey through the world of chemistry. In this video, we'll explore the three main types of chemical bonding- ionic bond, covalent bond, and coordinate bond. Through some exciting examples, I'll explain how atoms join together to form compounds, and the unique properties that arise from these bonds.\
    This video is perfect for CBSE students from class IX to XII who are looking to gain a deeper understanding of chemical bonding. It's a great starting point for those who are just beginning to explore the wonders of chemistry, and is also perfect for those who need a refresher course. Stay tuned for more detailed videos on each topic, but for now, sit back, relax, and get ready to learn about the basics of chemical bonding.\
    #chemistry #cbse #class11"
  },
  {
    ch : 4,
    chTitle: "Chemical Bonding", 
    id : "yM_6UlCeLhs",
    title: "Chemical Bonding | Class 11 Chapter 4 | Kossel - Lewis Theory | Octet Rule",
    description: "Are you struggling to understand chemical bonding in your CBSE chemistry class? Do you want to learn about a fundamental theory that can help you predict the formation of chemical bonds? Look no further than the Kossel-Lewis theory! In this video, I'll take you on a journey through this theory and show you how it can be used to determine the number of valence electrons in an atom, as well as how it can be applied to the formation of both ionic and covalent bonds.\
    The Kossel-Lewis theory, also known as the electron-dot structure, was developed by Gilbert Lewis and Walther Kossel in the early 20th century. It is a fundamental concept in chemistry that helps us understand how atoms form chemical bonds. The theory states that atoms tend to gain, lose, or share electrons to achieve a stable configuration of eight valence electrons, also known as the octet rule. By following the octet rule, atoms can attain a stable electron configuration similar to that of the noble gases, which are chemically inert.\
    In this video, I'll explain the Kossel-Lewis theory in detail, and show you how it is used to determine the number of valence electrons in an atom. I'll also introduce you to Lewis structures, which are diagrams that represent the bonding and nonbonding electrons in a molecule. With some exciting examples, I'll illustrate how the Kossel-Lewis theory can be used to predict the formation of both ionic and covalent bonds.\
    This video is perfect for students from CBSE class IX to XII who are looking to gain a deeper understanding of chemical bonding. It's a great starting point for those who are just beginning to explore the wonders of chemistry, and is also perfect for those who need a refresher course. Get ready to be amazed by the simplicity and elegance of the Kossel-Lewis theory."
  },
  {
    ch : 4,
    chTitle: "Chemical Bonding", 
    id : "dShK2sdfJQM",
    title: "Formal Charge with Examples | Class XI Chemistry - Chapter 4",
    description: "Formal charge is a measure of the hypothetical charge on an atom in a molecule. It is used to determine the distribution of electrons in a molecule and to predict the type of bond that would form between atoms. Formal charge is calculated by taking the difference between the number of valence electrons of an atom and the number of electrons assigned to the atom in the Lewis structure.\
    In this video, we will explain the concept of formal charge in detail, and guide you through its calculation using practical examples. Understanding formal charge is crucial for predicting the reactivity of molecules, as well as understanding the principles of chemical bonding.\
    In addition to explaining the concept of formal charge, we will also show you how to calculate formal charge for individual atoms within a molecule. This process involves determining the valence electrons of each atom, assigning electrons to each atom based on the Lewis structure, and comparing the total number of electrons to the expected number of valence electrons."
  },
  {
    ch : 4,
    chTitle: "Chemical Bonding", 
    id : "-jCWL5v-rHs",
    title: "Chemical Bonding Class11 One Shot | CBSE NEET JEE",
    description: "Welcome to introductory video on chemical bonding, where we'll take you on a fascinating journey through the world of chemistry. In this video, we'll explore the three main types of chemical bonding- ionic bond, covalent bond, and coordinate bond. Through some exciting examples, I'll explain how atoms join together to form compounds, and the unique properties that arise from these bonds.\
    This video is perfect for CBSE students from class IX to XII who are looking to gain a deeper understanding of chemical bonding. It's a great starting point for those who are just beginning to explore the wonders of chemistry, and is also perfect for those who need a refresher course. Stay tuned for more detailed videos on each topic, but for now, sit back, relax, and get ready to learn about the basics of chemical bonding.\
    #chemistry #cbse #class11"
  },
  {
    ch : 4,
    chTitle: "Chemical Bonding", 
    id : "BGNQNaeUGQc",
    title: "CHEMICAL BONDING in 1 Shot - All Concepts, Tricks & PYQs Covered | JEE Main & Advanced",
    description: "Are you struggling to understand chemical bonding in your CBSE chemistry class? Do you want to learn about a fundamental theory that can help you predict the formation of chemical bonds? Look no further than the Kossel-Lewis theory! In this video, I'll take you on a journey through this theory and show you how it can be used to determine the number of valence electrons in an atom, as well as how it can be applied to the formation of both ionic and covalent bonds.\
    The Kossel-Lewis theory, also known as the electron-dot structure, was developed by Gilbert Lewis and Walther Kossel in the early 20th century. It is a fundamental concept in chemistry that helps us understand how atoms form chemical bonds. The theory states that atoms tend to gain, lose, or share electrons to achieve a stable configuration of eight valence electrons, also known as the octet rule. By following the octet rule, atoms can attain a stable electron configuration similar to that of the noble gases, which are chemically inert.\
    In this video, I'll explain the Kossel-Lewis theory in detail, and show you how it is used to determine the number of valence electrons in an atom. I'll also introduce you to Lewis structures, which are diagrams that represent the bonding and nonbonding electrons in a molecule. With some exciting examples, I'll illustrate how the Kossel-Lewis theory can be used to predict the formation of both ionic and covalent bonds.\
    This video is perfect for students from CBSE class IX to XII who are looking to gain a deeper understanding of chemical bonding. It's a great starting point for those who are just beginning to explore the wonders of chemistry, and is also perfect for those who need a refresher course. Get ready to be amazed by the simplicity and elegance of the Kossel-Lewis theory."
  },
  {
    ch : 0,
    chTitle: "Miscelleneous", 
    id : "bGXvIov1tdQ",
    title: "Mole Concept problems in two minutes | Class XI - Chapter 1 | Chemistry for NEET, JEE",
    description: "Welcome to Elemental Experts, the premier destination for students seeking to master chemistry for the Class XI and XII CBSE curriculum with a focus on NEET and JEE exams. In this video, we will explore the mole concept, a foundational concept in chemistry that is essential for understanding many other topics in this field.\
    At the heart of the mole concept is the idea that every substance is made up of a large number of tiny particles, such as atoms, molecules, or ions. While it is possible to count the number of particles in a small sample, it is difficult to do so with larger samples. This is where the mole concept comes in, providing a convenient and efficient way to measure the amount of a substance.\
    The mole is a unit of measurement for the number of particles in a substance. Our expert teachers will guide you through the process of calculating the number of moles from the mass of a substance and its molar mass. We will also explain the difference between molarity and molality, two concepts often confused by students, and demonstrate how to calculate them.\
    Applications of the mole concept, starting with stoichiometry, which involves the quantitative relationship between reactants and products in a chemical reaction. Using examples and diagrams, we will show you how to apply the mole concept to stoichiometry problems, helping you understand the process of balancing chemical equations and calculating the quantities of reactants and products.\
    The mole concept plays important role in other areas of chemistry, such as solutions and gas laws. You will learn how to calculate the concentration of a solution, and how to apply the ideal gas law to determine the volume, pressure, and temperature of gases.\
    By the end of this video, you will have a thorough understanding of the mole concept, a crucial foundation for success in chemistry studies and the NEET and JEE exams. Our expert teachers will provide you with clear explanations, examples, and practice problems to ensure that you master this topic.\
    So, join us at Elemental Experts and take the first step towards mastering the mole concept and unlocking the world of chemistry. Don't forget to like, share, and subscribe to our channel for more engaging and informative chemistry education content!"
  }
]

const chapters = [
  { id: 'basic-concepts', name: 'Some Basic Concepts of Chemistry', notesUrl: 'https://docs.google.com/document/d/e/2PACX-1vRrNDnGY2XCvu4n6ICxzbepl9dMAtPSUD5dGrhBMzPUV_xmyCM_Cmscy46qwKi6JKUW3h1TWV8Mq3y4/pub?embedded=true' }
  // Add more chapter objects as needed
];

root.render(
  
  <BrowserRouter>
  <HelmetProvider>
  <Helmet>
    <title>Elemental Experts</title>
    <meta
        name="description"
        content="Excel in chemistry with Elemental Experts! Get personalized one-on-one online tuitions, video courses, and flashcards for CBSE, state boards, NEET, and JEE. Master chemical bonding and build a strong foundation with engaging MCQs. Join now for top-quality chemistry education from expert tutors" 
    />
    <meta charset="utf-8" />
    <meta name="theme-color" content="#000000"/>
    <meta name="viewport" content="width=device-width, initial-scale=1"/>
    <meta name="apple-mobile-web-app-status-bar-style" content="#000000"/>
    <link rel="sitemap" type="application/xml" title="Sitemap" href="%PUBLIC_URL%/urllist.txt"/>
    
    <link rel="shortcut icon" href="/favicon.ico"/>
    <link rel="preconnect" href="https://fonts.googleapis.com"/>
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>

    <link rel="preload"
    as="style"
    href="https://fonts.googleapis.com/css2?family=Inconsolata&display=swap" />
    
    <link rel="preload"
    as="style"
    href="https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap" />
    
    <link rel="preload"
    as="style"
    href="https://fonts.googleapis.com/css?family=Montserrat&display=swap" />

    <link href="https://fonts.googleapis.com/css2?family=Inconsolata&display=swap" rel="stylesheet" media="print" onload="this.media='all'"/>
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap" rel="stylesheet" media="print" onload="this.media='all'"/>
    <link href="https://fonts.googleapis.com/css?family=Montserrat&display=swap" rel="stylesheet" media="print" onload="this.media='all'"/>
    
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"/>
  	<link href="https://fonts.googleapis.com/css?family=Montserrat:900&display=swap" rel="stylesheet"/>
    <link rel="icon" href="/favicon.ico" />

    <noscript>
      {`<link href="https://fonts.googleapis.com/css2?family=Inconsolata&display=swap" rel="stylesheet"/><link href="https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap" rel="stylesheet"/><link href="https://fonts.googleapis.com/css?family=Montserrat&display=swap" rel="stylesheet"/>`}
    </noscript>
    <link
        href="//cdnjs.cloudflare.com/ajax/libs/KaTeX/0.9.0/katex.min.css"
        rel="stylesheet"
    />
    <link rel="apple-touch-icon" href="/logo-small.png" />
    <link rel="manifest" href="/manifest.json" />
  </Helmet>
  <Header />
  <Routes>
  <Route path="/" element={<LandingPage />} />
  <Route path="/chemistry" element={<VideoList videos={videos}/>} />
  <Route path="/flashcards" element={<FlashCards/>}>
    {/* <Route path="/flashcards/:topic" element={<FlashCardTopicPage />} /> */}
  </Route>
  <Route path="/flashcards/:topic" element={<FlashCardTopicPage />} />
  <Route path="/flashcards/:topic/:id" element={<FlashCardTopicPage />} />
  <Route path="/flashcards/:topic/:id/:page" element={<FlashCardTopicPage />} />

  <Route path="/notes" element={<ChapterList chapters={chapters}/>}>
    {/* <Route path="/chat/:topic" element={<FlashCardTopicPage />} /> */}
  </Route>
  <Route path="/notes/:id" element={<ChapterPage chapters={chapters}/>} />
  
  <Route path="/addcards" element={<AddFlashCardDeck topics={flashcardTopics } /> } />
  <Route path="*" element={<NotFound />} />
  </Routes>
  <Footer/>
  </HelmetProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
